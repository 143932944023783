import router from './router'
import { useAppStoreWithOut } from '@/store/modules/app'
import { useCache } from '@/hooks/web/useCache'
import type { RouteRecordRaw } from 'vue-router'
import { useTitle } from '@/hooks/web/useTitle'
import { useNProgress } from '@/hooks/web/useNProgress'
import { usePageLoading } from '@/hooks/web/usePageLoading'
import { getToken } from '@/utils/auth'
import { appRouter } from '@/router'

import { usePermissionStoreWithOut } from '@/store/modules/permission'
const permissionStore = usePermissionStoreWithOut()
permissionStore.setRoutes(appRouter)

const { start, done } = useNProgress()

const { loadStart, loadDone } = usePageLoading()

const whiteList = ['/login', '/register'] // 不重定向白名单

router.beforeEach(async (to, from) => {
  start()
  loadStart()
  const token = getToken()
  if (token) {
    if (to.path === '/login') {
      return {
        path: '/'
      }
    } else {
      return true
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      return true
    } else {
      return {
        path: `/login?redirect=${to.path}`
      }
    }
  }
})

router.afterEach((to) => {
  useTitle(to?.meta?.title as string)
  done() // 结束Progress
  loadDone()
})
