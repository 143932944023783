const config: {
  base_url: {
    base: string
    dev: string
    pro: string
    test: string
  }
  result_code: number | string
  default_headers: AxiosHeaders
  request_timeout: number
} = {
  /**
   * api请求基础路径
   */
  base_url: {
    // 开发环境接口前缀
    base: '',

    // 打包开发环境接口前缀
    //dev: 'http://110.40.167.224:8010',
    // dev: 'http://110.40.167.224:9090/api/v1',
    dev: 'https://admin.genie-robot.com/v1',

    // 打包测试环境接口前缀
    // test: 'http://110.40.167.224:9090/api/v1',
    test: 'https://admin.genie-robot.com/v1',

    // 打包生产环境接口前缀
    // pro: 'http://110.40.167.224:8010/api/v1'
    pro: 'https://admin.genie-robot.com/v1'
  },

  /**
   * 接口成功返回状态码
   */
  result_code: 200,

  /**
   * 接口请求超时时间
   */
  request_timeout: 60000,

  /**
   * 默认接口请求类型
   * 可选值：application/x-www-form-urlencoded multipart/form-data
   */
  default_headers: 'application/json'
}

export { config }
