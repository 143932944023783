import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
export const RouterPath = {
  root: '',
  register: '/register',
  login: '/login',
  homeRoot: '/home',
  homeChildPath: {
    home: '/home'
  },
  businessRoot: '/business',
  businessChildPath: {
    businessPresale: '/business/presale',
    businessHistoryList: '/business/historyList',
    businessAferSale: '/business/afterSale', //售后服务
    priceCheck: '/business/priceCheck', //报价核对
    businessPresaleDetail: '/business/presale-detail/:id',
    businessApprovePriceOrder: '/business/approve-price-order/:id',
    businessApproveMaterial: '/business/approve-material/:id',
    businessApproveCraft: '/business/approve-craft/:id/:craftId',
    businessSale: '/business/sale',
    //businessSaleDetail: '/business/sale-detail/:id/:productOrder',
    businessSaleDetail: '/business/sale-detail/:id/:productOrder/:saleStatus',
    businessStatistics: '/business/sale-statistics',
    quotedPrice: '/business/quoted-price/:id',
    contractList: '/business/presale/:presaleId/contract',
    contractDetail: '/business/presale/:presaleId/contract/:contractId',
    ContractPreview: '/business/contract-preview/:contractId',
    QuotePreview: '/business/quote-preview/:id/:template'
  },

  maintainRoot: '/maintain',
  maintainChildPath: {
    maintainSupplier: '/maintain/supplier',
    maintainMaterial: '/maintain/material',
    maintainCraft: '/maintain/craft'
  },

  purchasingRoot: '/purchasing',
  purchasingChildPath: {
    purchasingQuest: '/purchasing/quest',
    purchasingRecords: '/purchasing/records'
  },

  logisticsRoot: '/logistics',
  logisticsChildPath: {
    logisticsDeclare: '/logistics/declare',
    logisticsDelivery: '/logistics/delivery',
    logisticsVehicle: '/logistics/vehicle'
  },

  financeRoot: '/finance',
  financeChildPath: {
    financial: '/finance/financial', //财务统计
    invoiceLedger: '/finance/invoice', //开票台账
    payableLedger: '/finance/payable', //应付台账
    projectLedger: '/finance/project', //项目台账
    receivableLedger: '/finance/receivable', //应收台账
    reimbursementLedger: '/finance/reimbursement' //报销台账
  },

  ProductRoot: '/product',
  productChildPath: {
    ProductQR: '/product/QR',
    Productionflow: '/product/flow',
    POProductionReview: '/product/po-production-review',
    ProductList: '/product/list',
    ProductDetail: '/product/detail/:id',
    ProductPlain: '/product/plain',
    ProductProcessDetail: '/product/detail/:detailId/process/:processId'
  },

  SettingRoot: '/Setting',
  settingChildPath: {
    Personnelsetup: '/setting/personnelsetup',
    Warehousesetup: '/setting/warehousesetup'
  },

  // 技术管理
  TechRoot: '/tech',
  techChildPath: {
    TechPrepare: '/tech/prepare', // 技术准备
    ProcessLedger: '/tech/craft', // 工艺台账
    POTechnicalReview: '/tech/poTechnicalReview', // PO技术评审
    CraftDetail: '/tech/craft/detail/:id', // 工艺详情
    TechJudge: '/tech/dosing/:id',
    ProcessDetail: '/tech/process/detail/:id',
    TechDosingDetail: '/tech/dosing-detail/:id',
    TechDrawing: '/tech/drawing/:id',
    IngredientsLedger: '/tech/ingredients-ledger',
    IngredientsLedgerDetail: '/tech/ingredients-ledger/detail'
  },

  // 质量管理
  QualityRoot: '/quality',
  QualityChildPath: {
    InspectionLedger: '/quality/inspectionledger',
    Substandard: '/quality/substandard',
    SubstandardDetail: '/quality/substandard/detail/:id',
    TechJudge: '/quality/techjurdge'
  },

  // 文件管理
  FileRoot: '/file',
  FileChildPath: {
    FileManage: '/file/filemanage',
    PriceHistoryList: '/file/priceHistoryList',
    CraftFileManage: '/file/craftFileManage',
    CraftCardManage: '/file/craftCardManage',
    IngredientFileManage: '/file/ingredientFileManage',
    POList: '/file/polist',
    DrawingPackage: '/file/drawingPackage',
    InvoiceList: '/file/invoice',
    Template: '/file/template',
    POPackage: '/file/popackage',
    MaterialReport: '/file/materReport',
    //添加预览部分
    FilePreview: '/file/filePreview/:craftFileid/:file_name'
  },

  //仓库管理
  warehouseRoot: '/warehouse',
  warehouseChildPath: {
    WarehouseManage: '/warehouse/manage',
    OutboundManage: '/warehouse/outbound',
    WarehouseMaterial: '/warehouse/material',
    warehousestatistics: '/warehouse/statistics',
    warehouseExcelInPrint: 'wareshouse/excelIn',
    warehouseExcelOutPrint: 'wareshouse/excelOut'
  },
  // 设置
  UserRoot: '/setup',
  userChildPath: {
    UserList: '/setup/userList',
    SystemDetail: '/setup/systemDetail',
    WarehouseSetup: '/setup/warehouseSetup', // 仓库设置
    PersonnelSetup: '/setup/personnelSetup', // 角色管理
    NumberSetting: '/setup/numberSetting' // 编号设置
  },
  // 行政管理
  AdministrativeRoot: '/administrative',
  AdministrativeChildPath: {
    HumanResources: '/administrative/human', // 人力资源
    AccessControl: '/administrative/access', // 门禁管理
    Dining: '/administrative/dining', // 餐饮管理
    Assets: '/administrative/assets', // 资产管理
    Company: '/administrative/company' // 公司信息
  },
  // 流水
  FlowRoot: '/flow',
  flowChildPath: {
    OrderFlow: '/flow/orderFlow', //订单日志
    // QRFlow: '/flow/QRFlow', //扫码流水
    IngredientsFlow: '/flow/ingredientsFlow', // 配料流水
    Routeflow: '/flow/routeflow', // 路线流水
    Productionflow: '/flow/productionFlow' // 生产流水
  },

  // 电子看板
  ElectronicDisplayRoot: '/display',
  ElectronicDisplayChildPath: {
    BusinessDisplay: '/display/business', //业务部
    TechnicalDisplay: '/display/technical', // 技术部
    ProductionDisplay: '/display/production', // 生产部
    QualityDisplay: '/display/quality', // 质检部
    WarehouseDisplay: '/display/warehouse', // 仓库部
    PurchasingDisplay: '/display/purchasing', // 采购部
    LogisticsDisplay: '/display/logistics', // 物流部
    FinanceDisplay: '/display/finance' // 财务部
  },

  // 部门主管
  DepartmentRoot: '/department',
  DepartmentChildPath: {
    GeneralManager: '/department/manager', // 总经办
    BusinessSupervisor: '/department/business', // 业务部
    TechnicalSupervisor: '/department/technical', // 技术部
    ProductionSupervisor: '/department/production', // 生产部
    QualitySupervisor: '/department/quality', // 质检部
    WarehouseSupervisor: '/department/warehouse', // 仓库部
    PurchasingSupervisor: '/department/purchasing', // 采购部
    LogisticsSupervisor: '/department/logistics', // 物流部
    FinanceSupervisor: '/department/finance', // 财务部
    AdministrationSupervisor: '/department/administration' // 行政部
  }
}

const isDev = import.meta.env.VITE_ENV === 'dev'

export const appRouter: AppRouteRecordRaw[] = [
  {
    path: RouterPath.root,
    component: Layout,
    name: 'Root',
    redirect: { name: 'HomePage' },
    meta: {
      alwaysShow: false,
      hidden: true
    }
  },
  {
    path: RouterPath.homeRoot,
    component: Layout,
    name: 'HomePageRoot',
    redirect: RouterPath.homeChildPath.home,
    meta: {
      alwaysShow: false,
      hidden: true
    },
    children: [
      {
        path: RouterPath.homeChildPath.home,
        name: 'HomePage',
        component: () => import('@/views/HomePage/HomePage.vue'),
        meta: {
          alwaysShow: false,
          hidden: true
        }
      }
    ]
  },
  {
    path: RouterPath.businessRoot,
    component: Layout,
    name: 'BusinessRoot',
    redirect: RouterPath.businessChildPath.businessPresale,
    meta: {
      title: '业务管理',
      alwaysShow: true,
      hidden: false
    },
    children: [
      {
        path: RouterPath.businessChildPath.businessPresale,
        name: 'Presale',
        component: () => import('@/views/Business/Presale.vue'),
        meta: {
          hidden: false,
          title: '售前报价'
        }
      },
      {
        path: RouterPath.businessChildPath.businessSale,
        name: 'BusinessSale',
        component: () => import('@/views/Business/Sale/Sale.vue'),
        meta: {
          hidden: false,
          title: '销售跟踪'
        }
      },
      {
        path: RouterPath.businessChildPath.businessAferSale,
        name: 'BusinessAferSale',
        component: () => import('@/views/Business/AfterSale/AfterSale.vue'),
        meta: {
          hidden: false,
          title: '售后服务'
        }
      },
      {
        path: RouterPath.businessChildPath.priceCheck,
        name: 'PriceCheck',
        component: () => import('@/views/Business/PriceCheck/PriceCheck.vue'),
        meta: {
          hidden: false,
          title: '报价核对'
        }
      },
      {
        path: RouterPath.businessChildPath.businessHistoryList,
        name: 'BusinessHistoryList',
        component: () => import('@/views/Business/HistoryOrder/HistoryList.vue'),
        meta: {
          hidden: false,
          title: '核价单'
        }
      },
      {
        path: RouterPath.businessChildPath.businessSaleDetail,
        name: 'BusinessSaleDetail',
        component: () => import('@/views/Business/Sale/SaleDetail.vue'),
        meta: {
          hidden: true,
          title: '销售详情'
        }
      },
      isDev
        ? {
            path: RouterPath.businessChildPath.businessStatistics,
            name: 'businessStatistics',
            component: () => import('@/views/Business/Statistics.vue'),
            meta: {
              hidden: true,
              title: '销售统计'
            }
          }
        : undefined,

      {
        path: RouterPath.businessChildPath.businessPresaleDetail,
        name: 'PresaleDetail',
        component: () => import('@/views/Business/PresaleDetail.vue'),
        meta: {
          hidden: true,
          title: '意向单详情'
        }
      },
      {
        path: RouterPath.businessChildPath.businessApprovePriceOrder,
        name: 'ApprovePriceOrder',
        component: () => import('@/views/Business/ApprovePriceOrder.vue'),
        meta: {
          hidden: true,
          title: '意向单详情'
        }
      },
      {
        path: RouterPath.businessChildPath.businessApproveMaterial,
        name: 'ApproveMaterial',
        component: () => import('@/views/Business/ApproveMaterial.vue'),
        meta: {
          hidden: true,
          title: '材料核价'
        }
      },
      {
        path: RouterPath.businessChildPath.businessApproveCraft,
        name: 'ApproveCraft',
        component: () => import('@/views/Business/ApproveCraft.vue'),
        meta: {
          hidden: true,
          title: '工艺核价'
        }
      },
      {
        path: RouterPath.businessChildPath.quotedPrice,
        name: 'QuotedPrice',
        component: () => import('@/views/Business/QuotedPriceV2.vue'),
        meta: {
          hidden: true,
          title: '报价单'
        }
      },
      {
        path: RouterPath.businessChildPath.contractList,
        name: 'ContractList',
        component: () => import('@/views/Business/ContractList.vue'),
        meta: {
          hidden: true,
          title: '合同列表'
        }
      },
      {
        path: RouterPath.businessChildPath.contractDetail,
        name: 'ContractDetail',
        component: () => import('@/views/Business/ContractV3.vue'),
        meta: {
          hidden: true,
          title: '合同 '
        }
      },
      {
        path: RouterPath.businessChildPath.ContractPreview,
        name: 'ContractPreview',
        component: () => import('@/views/Business/ContractPreview.vue'),
        meta: {
          hidden: true,
          title: '合同预览'
        }
      },

      {
        path: RouterPath.businessChildPath.QuotePreview,
        name: 'QuotePreview',
        component: () => import('@/views/Business/QuotePreview.vue'),
        meta: {
          hidden: true,
          title: '报价单预览'
        }
      }
    ].filter(Boolean)
  },

  {
    path: RouterPath.TechRoot,
    component: Layout,
    name: 'TechRoot',
    meta: {
      title: '技术管理',
      hidden: false
    },
    redirect: RouterPath.techChildPath.TechPrepare,
    children: [
      {
        path: RouterPath.techChildPath.POTechnicalReview,
        name: 'POTechnicalReview',
        component: () => import('@/views/Tech/POTechnicalReview/POTechnicalReview.vue'),
        meta: {
          title: 'PO技术评审',
          hidden: false
        }
      },
      {
        path: RouterPath.techChildPath.IngredientsLedger,
        name: 'IngredientsLedger',
        component: () => import('@/views/Tech/Ingredients/IngredientsLedger.vue'),
        meta: {
          title: '配料台账',
          hidden: false
        }
      },
      {
        path: RouterPath.techChildPath.IngredientsLedgerDetail,
        name: 'IngredientsLedgerDetail',
        component: () => import('@/views/Tech/Ingredients/IngredientsLedgerDetail.vue'),
        meta: {
          title: '配料详情',
          hidden: true
        }
      },
      {
        path: RouterPath.techChildPath.TechPrepare,
        name: 'TechPrepare',
        component: () => import('@/views/Tech/Prepare/index.vue'),
        meta: {
          title: '技术准备',
          hidden: false
        }
      },
      {
        path: RouterPath.techChildPath.ProcessLedger,
        name: 'CraftLedger',
        component: () => import('@/views/Tech/CraftLedger/index.vue'),
        meta: {
          title: '工艺台账',
          hidden: false
        }
      },
      {
        path: RouterPath.techChildPath.CraftDetail,
        name: 'CraftDetail',
        component: () => import('@/views/Tech/CraftLedger/CraftDetail/ProcessDetails.vue'),
        meta: {
          title: '工艺详情',
          hidden: true
        }
      }
      // {
      //   path: RouterPath.techChildPath.ProcessDetail,
      //   name: 'ProcessDetail',
      //   component: () => import('@/views/Tech/CraftLedger/CraftDetail/ProcessDetail.vue'),
      //   meta: {
      //     title: '工序内容',
      //     hidden: true,
      //   }
      // },
      // {
      //   path: RouterPath.techChildPath.TechJudge,
      //   name: 'TechJudge',
      //   component: () => import('@/views/Tech/Judge/index.vue'),
      //   meta: {
      //     title: '技术判定',
      //     hidden: false
      //   }
      // }
    ].filter(Boolean)
  },

  //质量管理
  {
    path: RouterPath.QualityRoot,
    // path: '',
    component: Layout,
    name: 'qualityRoot',
    meta: {
      title: '质量管理',
      alwaysShow: true,
      hidden: false
    },
    redirect: RouterPath.QualityChildPath,
    children: [
      {
        path: RouterPath.QualityChildPath.InspectionLedger,
        name: 'inspectionLedger',
        component: () => import('@/views/Quality/InspectionLedger.vue'),
        meta: {
          title: '质检台账',
          hidden: false
        }
      },
      {
        path: RouterPath.QualityChildPath.Substandard,
        name: 'subStandard',
        component: () => import('@/views/Quality/Substandard.vue'),
        meta: {
          title: '不合格品台账',
          hidden: false
        }
      },

      {
        path: RouterPath.QualityChildPath.SubstandardDetail,
        name: 'substandardDetail',
        component: () => import('@/views/Quality/SubstandardDetail.vue'),
        meta: {
          title: '不合格品详情',
          hidden: true
        }
      },

      {
        path: RouterPath.QualityChildPath.TechJudge,
        name: 'techJudge',
        component: () => import('@/views/Quality/TechJudge.vue'),
        meta: {
          title: '技术判定',
          hidden: false
        }
      }
    ].filter(Boolean)
  },
  //文件管理
  {
    path: RouterPath.FileRoot,
    component: Layout,
    name: 'fileRoot',
    meta: {
      title: '文件管理',
      alwaysShow: true,
      hidden: false
    },
    redirect: RouterPath.FileChildPath,
    children: [
      {
        path: RouterPath.FileChildPath.FileManage,
        name: 'FileManage',
        component: () => import('@/views/File/FileManage.vue'),
        meta: {
          title: '文件汇总',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.PriceHistoryList,
        name: 'PriceHistoryList',
        component: () => import('@/views/File/PriceHistoryList.vue'),
        meta: {
          title: '报价单',
          hidden: false
        }
      },

      // {
      //   path: RouterPath.FileChildPath.FilePreview,
      //   name: 'FilePreview',
      //   component: () => import('@/views/File/FilePreview.vue'),
      //   meta: {
      //     hidden: true,
      //     title: '预览'
      //   }
      // },
      {
        path: RouterPath.FileChildPath.CraftFileManage,
        name: 'CraftFileManage',
        component: () => import('@/views/File/CraftFileList.vue'),
        meta: {
          title: '生产图纸',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.CraftCardManage,
        name: 'CraftCardManage',
        component: () => import('@/views/File/CraftCardList.vue'),
        meta: {
          title: '工艺文件',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.IngredientFileManage,
        name: 'IngredientFileManage',
        component: () => import('@/views/File/IngredientFileList.vue'),
        meta: {
          title: '配料文件',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.POList,
        name: 'POList',
        component: () => import('@/views/File/POList.vue'),
        meta: {
          title: 'PO单',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.DrawingPackage,
        name: 'DrawingPackage',
        component: () => import('@/views/File/DrawingFileList.vue'),
        meta: {
          title: '图纸包',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.InvoiceList,
        name: 'InvoiceList',
        component: () => import('@/views/File/InvoiceList.vue'),
        meta: {
          title: '发票',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.Template,
        name: 'Template',
        component: () => import('@/views/File/Template.vue'),
        meta: {
          title: '模板下载',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.POPackage,
        name: 'POPackage',
        component: () => import('@/views/File/POPackage.vue'),
        meta: {
          title: 'PO文件包',
          hidden: false
        }
      },
      {
        path: RouterPath.FileChildPath.MaterialReport,
        name: 'MaterialReport',
        component: () => import('@/views/File/MaterReport.vue'),
        meta: {
          title: '材质报告',
          hidden: false
        }
      }
    ].filter(Boolean)
  },
  {
    path: RouterPath.ProductRoot,
    // path: '',
    component: Layout,
    name: 'ProductRoot',
    meta: {
      title: '生产管理',
      alwaysShow: true,
      hidden: false
    },
    redirect: RouterPath.productChildPath.ProductList,
    children: [
      {
        path: RouterPath.productChildPath.ProductQR,
        name: 'ProductQR',
        component: () => import('@/views/Production/saoma.vue'),
        meta: {
          title: '扫码',
          hidden: false
        }
      },
      {
        path: RouterPath.productChildPath.Productionflow,
        name: 'Productionflow',
        component: () => import('@/views/Production/Productionflow.vue'),
        meta: {
          title: '生产流水',
          hidden: false
        }
      },
      {
        path: RouterPath.productChildPath.POProductionReview,
        name: 'POProductionReview',
        component: () => import('@/views/Production/POProductionReview.vue'),
        meta: {
          title: 'PO生产评审',
          hidden: false
        }
      },
      {
        path: RouterPath.productChildPath.ProductList,
        name: 'ProductionList',
        component: () => import('@/views/Production/List.vue'),
        meta: {
          title: '生产台账',
          hidden: false
        }
      },
      {
        path: RouterPath.productChildPath.ProductDetail,
        name: 'ProductDetail',
        component: () => import('@/views/Production/ProcessDetail.vue'),
        meta: {
          title: '生产详情',
          hidden: true
        }
      },
      {
        path: RouterPath.productChildPath.ProductPlain,
        name: 'ProductPlain',
        component: () => import('@/views/Production/ProductPlain.vue'),
        meta: {
          title: '调度规划',
          hidden: false
        }
      },
      {
        path: RouterPath.productChildPath.ProductProcessDetail,
        name: 'ProductProcessDetail',
        component: () => import('@/views/Production/ProcessDetail.vue'),
        meta: {
          title: '生产详情',
          hidden: true
        }
      }
    ].filter(Boolean)
  },
  {
    path: RouterPath.warehouseRoot,
    component: Layout,
    name: 'warehouseRoot',
    meta: {
      title: '仓库管理',
      alwaysShow: true,
      hidden: false
    },
    redirect: RouterPath.warehouseChildPath.WarehouseManage,
    children: [
      {
        path: RouterPath.warehouseChildPath.WarehouseManage,
        name: 'WarehouseManage',
        component: () => import('@/views/WareHouse/WareHouseInManage.vue'),
        meta: {
          title: '入库管理',
          hidden: false
        }
      },
      {
        path: RouterPath.warehouseChildPath.OutboundManage,
        name: 'OutboundManage',
        component: () => import('@/views/WareHouse/Out/WarehouseOutManage.vue'),
        meta: {
          title: '出库管理',
          hidden: false
        }
      },
      {
        path: RouterPath.warehouseChildPath.WarehouseMaterial,
        name: 'WarehouseMaterial',
        component: () => import('@/views/WareHouse/WarehouseMaterials/WarehouseMaterialsList.vue'),
        //component: () => import('@/views/WareHouse/warehousematerial.vue'),
        meta: {
          title: '仓库材料',
          hidden: false
        }
      },
      {
        path: RouterPath.warehouseChildPath.warehouseExcelInPrint,
        name: 'WarehouseExcelIn',
        component: () => import('@/views/WareHouse/ExcelPrintInView.vue'),
        meta: {
          hidden: true,
          title: '入库单-打印'
        }
      },
      {
        path: RouterPath.warehouseChildPath.warehouseExcelOutPrint,
        name: 'WarehouseExcelOut',
        component: () => import('@/views/WareHouse/ExcelPrintOutView.vue'),
        meta: {
          hidden: true,
          title: '出库单-打印'
        }
      }
    ]
  },
  {
    path: RouterPath.maintainRoot,
    component: Layout,
    name: 'MaintainRoot',
    meta: {
      title: '库管理',
      hidden: false
    },
    redirect: RouterPath.maintainChildPath.maintainSupplier,
    children: [
      {
        path: RouterPath.maintainChildPath.maintainSupplier,
        name: 'MaintainEnterprise',
        component: () => import('@/views/Maintain/Customer.vue'),
        meta: {
          title: '企业库',
          hidden: false
        }
      },
      {
        path: RouterPath.maintainChildPath.maintainMaterial,
        name: 'MaintainMaterial',
        component: () => import('@/views/Maintain/Material.vue'),
        meta: {
          title: '材料库',
          hidden: false
        }
      },
      {
        path: RouterPath.maintainChildPath.maintainCraft,
        name: 'MaintainCraft',
        component: () => import('@/views/Maintain/Craft.vue'),
        meta: {
          title: '工艺库',
          hidden: false
        }
      }
    ]
  },
  {
    path: RouterPath.purchasingRoot,
    component: Layout,
    name: 'PurchasingRoot',
    meta: {
      title: '采购管理',
      hidden: false
    },
    redirect: RouterPath.purchasingChildPath.purchasingQuest,
    children: [
      {
        path: RouterPath.purchasingChildPath.purchasingQuest,
        name: 'PurchasingQuest',
        component: () => import('@/views/Purchasing/PurchasingMange.vue'),
        meta: {
          title: '采购任务',
          hidden: false
        }
      },
      {
        path: RouterPath.purchasingChildPath.purchasingRecords,
        name: 'PurchasingRecords',
        component: () => import('@/views/Purchasing/PurchasingRecords.vue'),
        meta: {
          title: '采购记录',
          hidden: false
        }
      }
    ]
  },
  {
    path: RouterPath.logisticsRoot,
    component: Layout,
    name: 'LogisticsRoot',
    meta: {
      title: '物流管理',
      hidden: false
    },
    redirect: RouterPath.logisticsChildPath.logisticsDeclare,
    children: [
      {
        path: RouterPath.logisticsChildPath.logisticsDeclare,
        name: 'LogisticsDeclare',
        component: () => import('@/views/Logistics/Declare.vue'),
        meta: {
          title: '报关台账',
          hidden: false
        }
      },
      {
        path: RouterPath.logisticsChildPath.logisticsDelivery,
        name: 'LogisticsDelivery',
        component: () => import('@/views/Logistics/Delivery.vue'),
        meta: {
          title: '送货台账',
          hidden: false
        }
      },
      {
        path: RouterPath.logisticsChildPath.logisticsVehicle,
        name: 'LogisticsVehicle',
        component: () => import('@/views/Logistics/Vehicle.vue'),
        meta: {
          title: '车辆状态',
          hidden: false
        }
      }
    ]
  },

  {
    path: RouterPath.financeRoot,
    component: Layout,
    name: 'FinanceRoot',
    meta: {
      title: '财务管理',
      hidden: false
    },
    redirect: RouterPath.financeChildPath.financial,
    children: [
      {
        path: RouterPath.financeChildPath.financial,
        name: 'Financial',
        component: () => import('@/views/Finance/Financial.vue'),
        meta: {
          title: '财务统计',
          hidden: false
        }
      },
      {
        path: RouterPath.financeChildPath.invoiceLedger,
        name: 'InvoiceLedger',
        component: () => import('@/views/Finance/InvoiceLedger.vue'),
        meta: {
          title: '开票台账',
          hidden: false
        }
      },
      {
        path: RouterPath.financeChildPath.payableLedger,
        name: 'PayableLedger',
        component: () => import('@/views/Finance/PayableLedger.vue'),
        meta: {
          title: '应付台账',
          hidden: false
        }
      },
      {
        path: RouterPath.financeChildPath.projectLedger,
        name: 'ProjectLedger',
        component: () => import('@/views/Finance/ProjectLedger.vue'),
        meta: {
          title: '项目台账',
          hidden: false
        }
      },
      {
        path: RouterPath.financeChildPath.receivableLedger,
        name: 'ReceivableLedger',
        component: () => import('@/views/Finance/ReceivableLedger.vue'),
        meta: {
          title: '应收台账',
          hidden: false
        }
      },
      {
        path: RouterPath.financeChildPath.reimbursementLedger,
        name: 'ReimbursementLedger',
        component: () => import('@/views/Finance/ReimbursementLedger.vue'),
        meta: {
          title: '报销台账',
          hidden: false
        }
      }
    ]
  },

  {
    path: RouterPath.AdministrativeRoot,
    component: Layout,
    name: 'AdministrativeRoot',
    meta: {
      title: '行政管理',
      hidden: false
    },
    redirect: RouterPath.AdministrativeChildPath.HumanResources,
    children: [
      {
        path: RouterPath.AdministrativeChildPath.HumanResources,
        name: 'HumanResources',
        component: () => import('@/views/Administrative/HumanResources.vue'),
        meta: {
          title: '人力资源',
          hidden: false
        }
      },
      {
        path: RouterPath.AdministrativeChildPath.AccessControl,
        name: 'AccessControl',
        component: () => import('@/views/Administrative/AccessControl.vue'),
        meta: {
          title: '门禁系统',
          hidden: false
        }
      },
      {
        path: RouterPath.AdministrativeChildPath.Dining,
        name: 'Dining',
        component: () => import('@/views/Administrative/Dining.vue'),
        meta: {
          title: '餐饮系统',
          hidden: false
        }
      },
      {
        path: RouterPath.AdministrativeChildPath.Assets,
        name: 'Assets',
        component: () => import('@/views/Administrative/FixedAssets.vue'),
        meta: {
          title: '固定资产',
          hidden: false
        }
      },
      {
        path: RouterPath.AdministrativeChildPath.Company,
        name: 'Company',
        component: () => import('@/views/Administrative/CompanyInformation.vue'),
        meta: {
          title: '公司信息',
          hidden: false
        }
      }
    ]
  },

  {
    path: RouterPath.FlowRoot,
    component: Layout,
    name: 'FlowRoot',
    meta: {
      title: '流水',
      hidden: false
    },
    redirect: RouterPath.flowChildPath.OrderFlow,
    children: [
      {
        path: RouterPath.flowChildPath.OrderFlow,
        name: 'OrderFlow',
        component: () => import('@/views/Flow/OrderFlow.vue'),
        meta: {
          title: '订单流水',
          hidden: false
        }
      },
      // {
      //   path: RouterPath.flowChildPath.QRFlow,
      //   name: 'QRFlow',
      //   component: () => import('@/views/Flow/QRFlow.vue'),
      //   meta: {
      //     title: '扫码流水',
      //     hidden: false
      //   }
      // },
      {
        path: RouterPath.flowChildPath.IngredientsFlow,
        name: 'IngredientsFlow',
        component: () => import('@/views/Flow/IngredientsFlow.vue'),
        meta: {
          title: '配料流水',
          hidden: false
        }
      },
      {
        path: RouterPath.flowChildPath.Productionflow,
        name: 'ProductionFlow',
        component: () => import('@/views/Flow/ProductionFlow.vue'),
        meta: {
          title: '生产流水',
          hidden: false
        }
      },
      {
        path: RouterPath.flowChildPath.Routeflow,
        name: 'Routeflow',
        component: () => import('@/views/Flow/Routeflow.vue'),
        meta: {
          title: '路线流水',
          hidden: false
        }
      }
    ]
  },
  {
    path: RouterPath.UserRoot,
    component: Layout,
    name: 'UserRoot',
    meta: {
      title: '设置',
      alwaysShow: true,
      hidden: false
    },
    redirect: RouterPath.userChildPath.UserList,
    children: [
      {
        path: RouterPath.userChildPath.UserList,
        name: 'UserList',
        component: () => import('@/views/User/UserList.vue'),
        meta: {
          title: '用户管理',
          hidden: false
        }
      },
      {
        path: RouterPath.userChildPath.SystemDetail,
        name: 'SystemDetail',
        component: () => import('@/views/Business/FinancialSettings.vue'),
        meta: {
          title: '财务设置',
          hidden: false
        }
      },
      {
        path: RouterPath.userChildPath.WarehouseSetup,
        name: 'WarehouseSetup',
        component: () => import('@/views/Setup/Warehousesetup.vue'),
        meta: {
          title: '仓库设置',
          hidden: false
        }
      },
      {
        path: RouterPath.userChildPath.PersonnelSetup,
        name: 'PersonnelSetup',
        component: () => import('@/views/Setup/Role/PersonnelSetup.vue'),
        meta: {
          title: '角色管理',
          hidden: false
        }
      },
      {
        path: RouterPath.userChildPath.NumberSetting,
        name: 'NumberSetting',
        component: () => import('@/views/Setup/NumberSetting.vue'),
        meta: {
          title: '编号设置',
          hidden: false
        }
      }
    ].filter(Boolean)
  },
  {
    path: RouterPath.ElectronicDisplayRoot,
    component: Layout,
    name: 'ElectronicDisplayRoot',
    meta: {
      title: '电子看板',
      hidden: false
    },
    redirect: RouterPath.ElectronicDisplayChildPath.BusinessDisplay,
    children: [
      {
        path: RouterPath.ElectronicDisplayChildPath.BusinessDisplay,
        name: 'BusinessDisplay',
        component: () => import('@/views/ElectronicDisplay/BusinessDisplay.vue'),
        meta: {
          title: '业务部看板',
          hidden: false
        }
      },
      {
        path: RouterPath.ElectronicDisplayChildPath.TechnicalDisplay,
        name: 'TechnicalDisplay',
        component: () => import('@/views/ElectronicDisplay/TechnicalDisplay.vue'),
        meta: {
          title: '技术部看板',
          hidden: false
        }
      },
      {
        path: RouterPath.ElectronicDisplayChildPath.ProductionDisplay,
        name: 'ProductionDisplay',
        component: () => import('@/views/ElectronicDisplay/ProductionDisplay.vue'),
        meta: {
          title: '生产部看板',
          hidden: false
        }
      },
      {
        path: RouterPath.ElectronicDisplayChildPath.QualityDisplay,
        name: 'QualityDisplay',
        component: () => import('@/views/ElectronicDisplay/QualityDisplay.vue'),
        meta: {
          title: '质检部看板',
          hidden: false
        }
      },
      {
        path: RouterPath.ElectronicDisplayChildPath.WarehouseDisplay,
        name: 'WarehouseDisplay',
        component: () => import('@/views/ElectronicDisplay/WarehouseDisplay.vue'),
        meta: {
          title: '仓储部看板',
          hidden: false
        }
      },
      {
        path: RouterPath.ElectronicDisplayChildPath.PurchasingDisplay,
        name: 'PurchasingDisplay',
        component: () => import('@/views/ElectronicDisplay/PurchasingDisplay.vue'),
        meta: {
          title: '采购部看板',
          hidden: false
        }
      },
      {
        path: RouterPath.ElectronicDisplayChildPath.LogisticsDisplay,
        name: 'LogisticsDisplay',
        component: () => import('@/views/ElectronicDisplay/LogisticsDisplay.vue'),
        meta: {
          title: '物流部看板',
          hidden: false
        }
      },
      {
        path: RouterPath.ElectronicDisplayChildPath.FinanceDisplay,
        name: 'FinanceDisplay',
        component: () => import('@/views/ElectronicDisplay/FinanceDisplay.vue'),
        meta: {
          title: '财务部看板',
          hidden: false
        }
      }
    ]
  },
  {
    path: RouterPath.DepartmentRoot,
    component: Layout,
    name: 'DepartmentRoot',
    meta: {
      title: '部门主管',
      alwaysShow: true,
      hidden: false
    },
    redirect: RouterPath.DepartmentChildPath.GeneralManager,
    children: [
      {
        path: RouterPath.DepartmentChildPath.GeneralManager,
        name: 'GeneralManager',
        component: () => import('@/views/DepartmentHead/GeneralManager.vue'),
        meta: {
          title: ' 总经办',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.BusinessSupervisor,
        name: 'BusinessSupervisor',
        component: () => import('@/views/DepartmentHead/BusinessSupervisor.vue'),
        meta: {
          title: '业务部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.TechnicalSupervisor,
        name: 'TechnicalSupervisor',
        component: () => import('@/views/DepartmentHead/TechnicalSupervisor.vue'),
        meta: {
          title: '技术部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.ProductionSupervisor,
        name: 'ProductionSupervisor',
        component: () => import('@/views/DepartmentHead/ProductionSupervisor.vue'),
        meta: {
          title: '生产部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.QualitySupervisor,
        name: 'QualitySupervisor',
        component: () => import('@/views/DepartmentHead/QualitySupervisor.vue'),
        meta: {
          title: '质检部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.WarehouseSupervisor,
        name: 'WarehouseSupervisor',
        component: () => import('@/views/DepartmentHead/WarehouseSupervisor.vue'),
        meta: {
          title: '仓储部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.PurchasingSupervisor,
        name: 'PurchasingSupervisor',
        component: () => import('@/views/DepartmentHead/PurchasingSupervisor.vue'),
        meta: {
          title: '采购部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.LogisticsSupervisor,
        name: 'LogisticsSupervisor',
        component: () => import('@/views/DepartmentHead/LogisticsSupervisor.vue'),
        meta: {
          title: '物流部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.FinanceSupervisor,
        name: 'FinanceSupervisor',
        component: () => import('@/views/DepartmentHead/FinanceSupervisor.vue'),
        meta: {
          title: '财务部主管',
          hidden: false
        }
      },
      {
        path: RouterPath.DepartmentChildPath.AdministrationSupervisor,
        name: 'AdministrationSupervisor',
        component: () => import('@/views/DepartmentHead/AdministrationSupervisor.vue'),
        meta: {
          title: '行政部主管',
          hidden: false
        }
      }
    ].filter(Boolean)
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'RedirectLayout',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: RouterPath.login,
    component: () => import('@/views/User/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: '登录',
      noTagsView: true
    }
  },
  {
    path: RouterPath.register,
    component: () => import('@/views/User/Register.vue'),
    name: 'register',
    meta: {
      hidden: true,
      title: '注册',
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
].filter(Boolean)

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: appRouter as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

// export const resetRouter = (): void => {
//   const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'BusinessRoot']
//   router.getRoutes().forEach((route) => {
//     const { name } = route
//     if (name && !resetWhiteNameList.includes(name as string)) {
//       router.hasRoute(name) && router.removeRoute(name)
//     }
//   })
// }

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router
