import service from '@/service/service'
export const TokenKey = 'token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}
export function setAuth(token: string) {
  service.defaults.headers['Authorization'] = `Bearer ${token}`
  localStorage.setItem(TokenKey, token)
}
export function removeAuth() {
  service.defaults.headers['Authorization'] = undefined
  localStorage.removeItem(TokenKey)
}
export function initAuth() {
  const token = localStorage.getItem(TokenKey)
  console.log('token', token)
  service.defaults.headers['Authorization'] = `Bearer ${token}`
}
